<template>
	<v-app>
		<v-content class="blue-gradient">
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="12" md="6">
						<router-view />
					</v-col>
				</v-row>
			</v-container>
		</v-content>
	</v-app>
</template>
<script>
export default {
	name: 'layoutAuth',
}
</script>
<style lang="sass">
.card-login
	&.v-card .v-divider
		border-color: transparent !important
.blue-gradient
	background: linear-gradient(150deg,#F1F5F7,#D6E3E8) !important
	
</style>
